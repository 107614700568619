@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Benzin Semibold";
  src: url("./assets/static/fonts/Benzin-Semibold.ttf");
}

@font-face {
  font-family: "Benzin Regular";
  src: url("./assets/static/fonts/Benzin-Regular.ttf");
}
@font-face {
  font-family: "Benzin Bold";
  src: url("./assets/static/fonts/Benzin-Bold.ttf");
}
@font-face {
  font-family: "Benzin Medium";
  src: url("./assets/static/fonts/benzin-medium.ttf");
}

body {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  background-color: #111111;
}
.ns-main-sec {
  background-color: #111111;
}
.ns-new-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

.ns-new-container-sm {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 1rem;
}

.ns-myFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ns-header-sp {
  padding: 1rem 0;
}
.ns-header-sp ul {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Regular";
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.ns-header-sp ul li a {
  text-decoration: none;
  font-size: 1rem;
}
.ns-header-sp ul li a:hover {
  color: #6e4b86 !important;
}
.ns-header-btn,
.ns-header-btn-sec {
  border-radius: 100px;
  padding: 0.75rem 1.5rem;
  outline: none;
  border: none;
  background-color: #cfbfda;
  font-family: "Benzin Semibold";
  font-size: 1rem;
  cursor: pointer;
  box-sizing: border-box;
}

/* Mobile Menus  */
header .ns-menus-btn {
  display: none;
}
.ns-hidden-on-desk {
  display: none;
}
header .ns-mob-hidden.ns-active {
  display: block;
  position: fixed;
  z-index: 10000;
  right: 0;
  top: 0;
  background-color: #0b0c0c;
  color: #ffffff;
  width: 256px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.ns-menus-btn-close {
  position: absolute;
  top: 2.2rem;
  right: 1rem;
  background-color: transparent;
  font-size: 1.4rem;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}
/* Header end  */

/* HOme Page  */

/* Hero Section  */
.hero-bg {
  background: url(./assets/static/imgs/Background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ns-hero-sec {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  align-items: flex-end;
  padding: 3rem 0 5rem 0;
}
.ns-hero-left {
  width: 50%;
}
.ns-hero-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.ns-hero-right img {
  width: 100%;
  max-width: 375px;
  height: auto;
}
.ns-hero-left span {
  font-family: "Benzin Bold";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  font-size: 4rem;
}
.ns-hero-left h1 {
  padding: 0;
  margin: 0;
  max-width: 626px;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 3.5rem;
  background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ns-liear-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 68.63%, #111111 100%);
}
.ns-liear-bg img {
  width: 100%;
  max-width: 50px;
  margin-top: -2rem;
  height: auto;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/* End Hero Section  */

.ns-backs-sec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 3rem;
  padding: 5rem 0;
}
.ns-bs-right p {
  font-family: "Benzin Regular";
  color: #ffffff;
  line-height: 1.85;
  padding: 0;
  margin: 1.75rem 0 2.5rem 0;
  font-size: 1rem;
}
.ns-bs-right h3,
.ns-roadmap-left h3 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 1.8rem;
  background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ns-backs-sec img {
  max-width: 100%;
  height: auto;
}
.ns-bs-left {
  padding-left: 1rem;
}

/* Roadmap Sec  */
.ns-roadmap-sec {
  display: flex;
  padding: 5rem 0;
  align-items: center;
}
.ns-roadmap-left {
  width: 60%;
}
.ns-roadmap-right {
  width: 40%;
  max-width: 24%;
  margin: 0 auto;
}
.ns-roadmap-right img {
  max-width: 100%;
  height: auto;
}
.ns-roadmap-list {
  font-family: "Benzin Regular";
  margin-top: 1.3rem;
}
.ns-road-list-flex {
  display: flex;
  align-items: center;
  gap: 2.2rem;
}
.ns-roadmap-list span {
  display: inline-block;
  margin-top: 2.5rem;
  border: 1px solid #97979799;
  border-radius: 8px;
  background-color: #97979754;
  padding: 0.9rem 1.29rem;
  color: #ffffff;
  font-size: 1.125rem;
}

/* End Roadmap Sec  */
/* End HOme Page  */

/* Mint Page  */

.ns-mint-main-wrap {
  padding: 3rem 0 6rem 0;
  min-height: 100vh;
}

.ns-mint-sec {
  border-radius: 10px;
  border: 1px solid #94839f;
  background: rgba(40, 23, 51, 0.11);
  backdrop-filter: blur(8px);
  padding: 1.75rem 1rem 2.5rem 1rem;
}
.ns-mint-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.ns-mint-fields p {
  color: #ffffff;
  font-size: 1.85rem;
  padding: 0;
  margin: 0;
  font-family: "Benzin Regular";
}
.ns-mint-fields span {
  display: inline-block;
  border: 1px solid #94839f;
  margin-top: 0.5rem;
  border-radius: 9px;
  display: inline-block;
  color: #ffffff;
  padding: 0.5rem 1.24rem;
  font-size: 1.85rem;
  min-width: 85px;
  text-align: center;
  font-family: "Benzin Regular";
}
.ns-mint-heading-main {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 2.813rem;
  background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  max-width: 80%;
  margin: 0rem auto 2rem auto;
}
.ns-mint-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1.75rem;
}
.ns-mint-heading h4 {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 1.25rem;
  background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ns-mint-heading h4::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #6e4b86;
  width: 100%;
}
.ns-mint-heading span {
  border: 1px solid #94839f;
  margin-top: 0.5rem;
  border-radius: 9px;
  display: inline-block;
  color: #ffffff;
  padding: 0.5rem 1.24rem;
  font-size: 1rem;
  font-family: "Benzin Medium";
}
.ns-mint-counter {
  margin-top: 2rem;
}
.ns-mint-cb {
  margin-top: 1rem;
}
.ns-mint-cb,
.mint-counter-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ns-mint-cb button {
  outline: none;
  border: none;
  font-family: "Benzin Medium";
  display: inline-block;
  border: 1px solid #94839f;
  border-radius: 9px;
  color: #ffffff;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 1.85rem;
  text-align: center;
  cursor: pointer;
}
.ns-mint-cb button span {
  margin-top: -4px;
  display: inline-block;
}
.ns-mint-cb input {
  outline: none;
  border: none;
  width: 50px;
  text-align: center;
  font-size: 1.85rem;
  font-family: "Benzin Regular";
  background-color: transparent;
  color: #ffffff;
}

.ns-mint-btn-new {
  margin-top: 1.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ns-mint-btn-new button {
  border-radius: 100px;
  padding: 0.75rem 2.3rem;
  outline: none;
  border: none;
  background-color: #cfbfda;
  font-family: "Benzin Semibold";
  font-size: 1.25rem;
  cursor: pointer;
  box-sizing: border-box;
}
/* End Mint Page  */

/* Staking Page  */
.ns-new-container-stacking {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 1rem;
}
.ns-stack-main-wrap {
  padding: 5rem 0;
}
.ns-stack-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.ns-stack-card {
  border-radius: 10px;
  border: 1px solid #94839f;
  background: rgba(40, 23, 51, 0.11);
  backdrop-filter: blur(8px);
  padding: 1.75rem 1rem 2.5rem 1rem;
}
.ns-stack-card img {
  max-width: 100%;
  height: auto;
}
.ns-stack-card h4 {
  padding: 0;
  margin: 0 0 1rem 0;
  text-align: center;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 2.3rem;
  background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ns-nft-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ns-nft-type {
  padding: 0;
  margin: 0.5rem 0;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 1.5rem;
  background: linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ns-nft-status p {
  padding: 0;
  margin: 0;
  text-align: center;
  border: 1px solid #94839f;
  margin-top: 0.3rem;
  border-radius: 9px;
  color: #ffffff;
  padding: 0.5rem 1.24rem;
  font-size: 1.25rem;
  font-family: "Benzin Medium";
}

.ns-stackbtn-new {
  margin-top: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ns-stack-nft-btn {
  border-radius: 100px;
  padding: 0.75rem 2.3rem;
  outline: none;
  border: none;
  background-color: #cfbfda;
  font-family: "Benzin Semibold";
  font-size: 1.25rem;
  cursor: pointer;
  box-sizing: border-box;
}

/* DEtail Section  */
.ns-stak-details-container {
  display: grid;
  grid-template-columns: 1fr 350px 1fr 1fr;
  font-family: "Benzin Regular";
  gap: 1.75rem;
  color: #ffffff;
  padding-bottom: 3rem;
}
.ns-detail-stake {
  border: 1px solid #94839f;
  border-radius: 9px;
  padding: 0.85rem 0.75rem;
  background-color: #2817331c;
}
.ns-detail-flex-stake {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.ns-detail-stake img {
  max-width: 100%;
  height: auto;
}
.ns-details-logo {
  width: 45px;
}
.ns-detail-content {
  text-align: center;
  flex-grow: 1;
}
.ns-detail-stake span {
  display: block;
  text-align: center;
}
/* ENd DEtail Section  */
/* Work Page  */

.ns-flex-work {
  display: flex;
  justify-content: space-between;
  padding: 6rem 0;
  gap: 1rem;
  box-sizing: border-box;
}
.ns-work-left {
  flex-basis: 46%;
}
.ns-work-right {
  flex-basis: 44%;
}
.ns-work-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.ns-work-right img {
  max-width: 100%;
  height: auto;
}
.ns-work-left h3,
.ns-team-wrapper h3 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 3rem;
  background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ns-work-left p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin-top: 1.4rem;
  line-height: 1.8;
  box-sizing: border-box;
}
.ns-work-left div {
  margin-top: 4rem;
}

/* How WOrks End  */

footer {
  background-color: #0b0c0c;
  color: #ffffff;
}
.ns-footer-myFlex {
  display: flex;
  justify-content: space-between;
  padding: 3.5rem 0;
}
footer .ns-footer-logo {
  margin-top: 32px;
}
footer .ns-footer-links {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
footer .ns-footer-links h4 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Semibold";
  font-size: 1.25rem;
}
.ns-footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Regular";
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

footer ul li a {
  color: #ffffff;
  text-decoration: none;
}
footer ul li a:hover {
  text-decoration: underline;
}
footer .ns-footer-socials {
  display: flex;
  align-items: center;
  gap: 1rem;
}
footer .ns-footer-socials img {
  width: 40px;
}
footer .ns-copy-right {
  border-top: 1px solid #ffffff;
  margin: 0;
  padding: 1rem 0;
  text-align: center;
  font-family: "Benzin Regular";
}

/* Footer End  */

/* About Page  */
.ns-flex-team {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem;
  box-sizing: border-box;
}
.ns-team-wrapper {
  padding: 6rem 0;
}
.ns-team-left {
  /* flex-basis: 45%; */
  width: 100%;
  max-width: 500px;
  /* background-color: rgba(255, 68, 0, 0.247); */
}
.ns-team-right {
  /* background-color: aqua; */
  width: 100%;
  max-width: 500px;
}

.ns-member-card {
  position: relative;
}
.ns-member-card img {
  max-width: 100%;
  height: auto;
}
.ns-member {
  max-width: 350px;
  margin-top: 2rem;
}
.ns-member-2 {
  max-width: 260px;
  margin-top: 2rem;
}

.member-badge h5 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 1.25rem;
  color: #ffffff;
}
.member-badge {
  position: absolute;
  bottom: 80px;
  right: 0%;
  transform: translateX(100%);
  padding: 0.75rem 1.25rem;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
}
.ns-team-left p,
.ns-team-right p {
  font-size: 1rem;
  color: #ffffff;
  font-family: "Benzin Regular";
  line-height: 1.75;
}
.ns-team-left p span,
.ns-team-right p span {
  font-size: 1.5rem;
  color: #ffffff;
  font-family: "Benzin Regular";
  line-height: 1.75;
}

/* FAQs */
.ns-faq-container h5 {
  padding: 0;
  margin: 0;
}
.ns-faq-container {
  padding: 2rem 0 0 0;
  margin-top: 5rem;
}
.ns-faq-container .mb-4 {
  margin-bottom: 1rem;
  border: 1px solid #97979799;
  border-radius: 8px;
  overflow: hidden;
}
.ns-faq-container .ns-faq-wrapper {
  margin-top: 3rem;
}
.ns-faq-container .ns-faq-question {
  display: flex;
  background-color: #97979721;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  cursor: pointer;
}
.ns-faq-container img {
  max-width: 100%;
  height: auto;
  width: 16px;
  transition: all 0.3s ease-in-out;
}
.ns-faq-container h5 {
  font-size: 1rem;
  color: white;
  font-family: "Benzin Regular";
  font-weight: 400;
}
.ns-faq-container .ns-faq-answer {
  padding: 1.25rem;
}
.ns-faq-container .ns-faq-answer p {
  padding: 0;
  margin: 0;
  font-family: "Poppins";
  line-height: 1.8;
  color: white;
}
.ns-faq-container .ns-faq-heading {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 2.25rem;
  background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Staking Modal  */
.ns-stake-overlay {
  background-color: #11111193;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  box-sizing: border-box;
  overflow-y: auto;
}
.ns-modal-container {
  width: 100%;
  max-width: 1080px;
  border: 1px solid #94839f;
  border-radius: 10px;
  background: rgba(40, 23, 51, 0.11);
  backdrop-filter: blur(8px);
  padding: 2rem 2rem 2rem 2rem;
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.ns-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  overflow-y: auto;
}
.ns-modal-heading-flex {
  display: flex;
  align-items: center;
}
.ns-modal-heading {
  flex-grow: 1;
  text-align: center;
}
.ns-modal-heading h3 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Benzin Bold";
  font-size: 2.25rem;
  background: -webkit-linear-gradient(90deg, #8f7d9b -1.24%, #ffffff 45.49%, #b5aabc 97.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 2.5rem;
}
.ns-modal-heading-flex button {
  outline: none;
  border: none;
  font-family: "Benzin Medium";
  display: inline-block;
  border: 1px solid #94839f;
  border-radius: 9px;
  color: #ffffff;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
}
.ns-modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ns-modal-btn button {
  border-radius: 100px;
  padding: 0.75rem 2.3rem;
  outline: none;
  border: none;
  background-color: #cfbfda;
  font-family: "Benzin Semibold";
  font-size: 1.25rem;
  cursor: pointer;
  min-width: none;
  box-sizing: border-box;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.ns-modal-nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: 20px;
  margin: 1rem 0;
}
.ns-modal-nft-grid-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Example box shadow */
  text-align: center;
}
.ns-stake-modal-card {
  border: 1px solid #94839f;
  border-radius: 8px;
  background-color: #92929275;
}
.ns-stake-modal-card span {
  font-family: "Benzin Regular";
  font-size: 1.125rem;
  display: inline-block;
  padding: 0.75rem 0;
  color: #ffffff;
}
.ns-stake-modal-card img {
  width: 100%;
}
.ns-stake-modal-card-select {
  border: 1px solid #ff0000;
  border-radius: 8px;
  background-color: #92929275;
}
.ns-stake-modal-card-select span {
  font-family: "Benzin Regular";
  font-size: 1.125rem;
  display: inline-block;
  padding: 0.75rem 0;
  color: #ffffff;
}
.ns-stake-modal-card-select img {
  width: 100%;
}
/* Media Queries  */

@media (max-width: 1180px) {
  /* Work Page Queries  */
  .ns-work-left {
    flex-basis: 50%;
  }
  /* End Work Page Queries  */
}
@media (max-width: 1100px) {
  .ns-hidden-on-desk {
    display: block;
  }
  /* Staking Page  */
  .ns-stak-details-container {
    gap: 1rem;
    padding-bottom: 2.5rem;
  }
  /* End Staking Page  */

  /* HomePage Sec  */
  .ns-hero-left h1,
  .ns-hero-left span {
    font-size: 3rem;
  }
  /* End HomePage Sec  */
  /* Team Seection  */
  .ns-flex-team {
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  /* End Team Seection  */

  /* Work Page Queries  */
  .ns-work-left h3,
  .ns-team-wrapper h3 {
    font-size: 2rem;
  }
  .ns-work-left div {
    margin-top: 2rem;
  }
  .ns-work-right {
    align-items: center;
  }
  /* End Work Page Queries  */

  /* Header Queries  */
  .ns-header-sp ul {
    display: none;
  }

  .ns-header-btn {
    display: none;
  }
  header .ns-menus-btn {
    background-color: transparent;
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
  }
  /* ENd Header Queries  */
}
@media (max-width: 1024px) {
  /* Stacking Page  */
  .ns-stack-nft-btn {
    padding: 0.75rem 1.3rem;
    font-size: 1rem;
  }
  .ns-stak-details-container {
    display: grid;
    grid-template-columns: 1fr 300px 1fr;
    font-family: "Benzin Regular";
    color: #ffffff;
  }
  .ns-details-logo {
    width: 36px;
  }
  /* End Stacking Page  */

  .ns-hero-left h1 {
    font-size: 2.5rem;
  }
  .ns-hero-right img {
    max-width: 70%;
  }
  .ns-hero-left {
    width: auto;
  }
  .ns-roadmap-list span {
    margin-top: 2rem;
    padding: 0.9rem 1rem;
    font-size: 1rem;
  }
  .ns-road-list-flex {
    gap: 1.85rem;
  }
}
@media (max-width: 768px) {
  /* Stacking Page  */
  .ns-stack-main-wrap {
    padding: 3rem 0;
  }
  .ns-stack-cards-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  .ns-stak-details-container {
    grid-template-columns: 1fr 300px;
  }
  .ns-detail-stake span {
    display: block;
    text-align: center;
    font-size: 0.75rem;
  }
  .ns-details-logo {
    width: 36px;
  }
  /* End Stacking Page  */

  /* MINT PAGE  */
  .ns-mint-btn-new button {
    font-size: 1rem;
  }
  .ns-mint-heading-main {
    font-size: 2.4rem;
  }
  .ns-mint-fields span {
    font-size: 1.5rem;
  }

  /* End MINT PAGE  */
  /* Home Page Queries  */
  .ns-liear-bg img {
    max-width: 36px;
  }
  .ns-hero-sec {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 2rem 0 3rem 0;
  }
  .ns-hero-left h1,
  .ns-hero-left span {
    font-size: 2rem;
  }
  .ns-backs-sec {
    display: grid;
    grid-template-columns: 1fr;
    padding: 2rem 0;
  }
  .ns-bs-left {
    max-width: 500px;
    margin: 0 auto;
  }
  .ns-bs-left {
    padding-left: 0rem;
  }

  .ns-roadmap-sec {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
  }
  .ns-roadmap-left {
    width: 100%;
    margin-bottom: 2rem;
  }
  .ns-road-list-flex {
    display: block;
  }
  .ns-roadmap-right {
    width: 100%;
    max-width: 225px;
  }
  /* Home Page Queries End  */

  /* Team Seection  */
  .ns-member {
    max-width: 250px;
    margin-top: 2rem;
  }
  .ns-member-2 {
    max-width: 240px;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
  .ns-faq-container {
    padding: 1rem 0 0 0;
    margin-top: 3rem;
  }
  /* End Team Seection  */

  .ns-flex-work,
  .ns-team-wrapper {
    display: block;
    padding: 4rem 0;
  }
  .ns-work-right {
    flex-basis: 100%;
  }

  /* Header Queries  */
  header .ns-newlogo {
    max-width: 150px;
  }
  .ns-header-btn,
  .ns-header-btn-sec {
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
  }
  /* ENd Header Queries  */

  /* Footer Media Queries */
  footer .ns-footer-logo {
    flex-basis: 300px;
  }
  footer .ns-footer-logo {
    flex-basis: 280px;
  }
  .ns-footer-myFlex {
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
  }
  .ns-footer-desk {
    display: none !important;
  }
  footer .ns-footer-logo {
    margin-top: 0px;
  }
  /* End Footer Media Queries */
}
@media (max-width: 580px) {
  .ns-mint-heading-main {
    font-size: 2rem;
  }
  .ns-mint-heading-main {
    max-width: 100%;
  }
  .ns-hero-sec {
    flex-direction: column-reverse;
  }
  .ns-hero-right {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 540px) {
  .ns-stak-details-container {
    grid-template-columns: 1fr auto;
  }
  .ns-details-logo {
    width: 22px;
  }

  /* Team Section  */
  .member-badge {
    padding: 0.5rem 0.5rem;
    bottom: 60px;
  }
  .member-badge h5 {
    font-size: 1rem;
  }
  /* End Team Section  */

  /* Header Queries  */
  header .ns-newlogo {
    max-width: 120px;
  }
  /* End Header Queries  */

  /* Footer Media Queries */
  .ns-footer-myFlex {
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }
  footer .ns-footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  footer .ns-footer-links {
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
  }
  /* .ns-copy-right {
    padding: ;
  } */
  .ns-copy-right p {
    font-size: 0.85rem;
    padding: 0 1rem;
    margin: 0;
  }
  /* End Footer Media Queries */
}

@media (max-width: 480px) {
  /* Staking Modal  */
  .ns-modal-nft-grid {
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  }
  .ns-modal-container {
    padding: 2rem 1.25rem;
  }
  .ns-modal-heading h3 {
    font-size: 1.75rem;
    margin-left: 0;
  }
  .ns-modal-heading {
    text-align: left;
  }
  .ns-modal-heading-flex button {
    width: 32px;
    height: 32px;
  }
  /* End Staking Modal  */
  .ns-stak-details-container {
    grid-template-columns: 1fr;
  }
  .ns-detail-stake {
    padding: 0.55rem 0.45rem;
  }
  .ns-details-logo {
    width: 28px;
  }
  /* MINT PAGE  */
  .ns-mint-fields span {
    font-size: 1rem;
    padding: 0.75rem 1.24rem;
  }
  .ns-mint-main-wrap {
    padding: 3rem 0 6rem 0;
    min-height: 65vh;
  }

  /* Home Page  */
  .ns-hero-left h1,
  .ns-hero-left span {
    font-size: 1.75rem;
  }
  .ns-hero-right {
    width: 80%;
    display: block;
    justify-content: start;
  }
  .ns-hero-right img {
    max-width: 100%;
  }
  ns-liear-bg {
    min-height: 70px;
  }
  /* Home Page  */

  .ns-member {
    max-width: 198px;
  }
  .ns-member-2 {
    max-width: 149px;
    margin-bottom: 2rem;
  }
  .member-badge {
    padding: 0.5rem 0.5rem;
    bottom: 45px;
  }
  .member-badge h5 {
    font-size: 0.7rem;
  }
  .ns-faq-container {
    margin-top: 1rem;
  }
}
